<template>
  <div class="">
    <div class="social container hidden-xs-only">
      <div class="flex row-center">
        <div class="title">工作地点：</div>
        <ul class="flex job">
          <li
            :class="{ active: currentAddress === index }"
            v-for="(item, index) in address"
            :key="index"
            @click="changeAddress(item, index)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="flex row-center m-t-24">
        <div class="title">岗位类型：</div>
        <ul class="flex education">
          <!--  @mouseenter="mouseenter" -->
          <li
            :class="{ active: currentType === index }"
            v-for="(item, index) in positions"
            :key="index"
            @click="changeType(index)"
          >
            <span :class="index + ''"> {{ item.name }} </span>
          </li>
        </ul>
      </div>
      <transition name="el-zoom-in-top">
        <!-- <div v-show="show" class="transition-box">.el-fade-in-linear</div> -->
        <div
          v-if="currentType > 0 && positions[currentType].lists.length > 0"
          class="type-list"
        >
          <div
            class="item"
            :class="{ active: currentType1 === index }"
            v-for="(item, index) in positions[currentType].lists"
            :key="index"
            @click="changeType1(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </transition>

      <div class="flex row-center m-t-24">
        <div class="title">学历要求：</div>
        <ul class="flex education">
          <li
            :class="{ active: currentEducation == index }"
            v-for="(item, index) in educations"
            :key="index"
            @click="changeEducation(index)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item.id)"
        >
          <p class="name">{{ item.positionName }} [{{ item.city }}]</p>
          <div class="subtitle">
            <!-- 月薪{{ item.salary.split(",")[0] }} -
            {{ item.salary.split(",")[1] }}k | -->
            {{ item.duration }} | {{ educationText[item.education] }}
            <!-- |
            {{ item.recruits_num }}人 -->
            <!-- <span class="tag">{{ item.type_id == 1 ? "社招" : "校招" }}</span> -->
          </div>
        </li>
      </ul>
      <div class="page">
        <el-pagination
          :current-page.sync="params.page"
          :page-size="params.limit"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
        />
      </div>
    </div>

    <div class="hidden-sm-and-up">
      <div class="nav-bar">
        <van-nav-bar title="清微招聘" fixed placeholder>
          <template #left>
            <img
              src="http://www.tsingmicro.com/assets/img/logo@2x.png"
              alt=""
              width="80"
            />
          </template>
          <template #right>
            <van-icon name="friends" color="#fff" size="20" @click="goResume" />
          </template>
        </van-nav-bar>
        <van-dropdown-menu>
          <van-dropdown-item
            title="工作地点"
            v-model="value"
            :options="address"
            @change="addressChange"
          />
          <van-dropdown-item title="职位类型" ref="item">
            <van-tree-select
              :items="positions"
              :active-id.sync="activeId"
              :main-active-index.sync="activeIndex"
              @click-item="clickItem"
              @click-nav="clickNav"
            />
          </van-dropdown-item>
          <van-dropdown-item
            title="学历要求"
            v-model="value1"
            :options="educations"
            @change="educationChange"
          />
          <van-dropdown-item
            title="清微招聘"
            v-model="value2"
            :options="recruits"
            @change="recruitChange"
          />
        </van-dropdown-menu>

        <van-list
          style="margin-top: 4px"
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          offset="10"
          @load="getInfo"
        >
          <van-cell
            v-for="item in list"
            :key="item.id"
            @click="goDetail(item.id)"
          >
            <template #title>
              <div class="title-3">
                {{ item.positionName }} <span>[{{ item.city }}]</span>
              </div>
              <div class="subtitle flex">
                {{ item.duration }} | {{ educationText[item.education] }}
              </div>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { info, position } from "@/assets/js/api";
let flag =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
export default {
  data() {
    return {
      total: 0,
      activeId: 0,
      activeIndex: 0,
      value: 0,
      value1: 0,
      value2: 1,
      recruitText: {
        1: "社会招聘",
        0: "校园招聘",
      },
      recruits: [
        { text: "社会招聘", value: 1 },
        { text: "校园招聘", value: 0 },
      ],
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: "校园招聘", url: "/school" },
        { text: "我的投递", url: "/recruit-resume" },
      ],
      address: [
        { text: "全部", value: 0 },
        { text: "北京", value: 1 },
        { text: "上海", value: 2 },
        { text: "南京", value: 3 },
        { text: "深圳", value: 4 },
        { text: "成都", value: 5 },
        { text: "杭州", value: 6 },
        { text: "天津", value: 7 },
      ],
      educationText: {
        0: "本科及以上",
        1: "硕士及以上",
        2: "博士",
      },
      educations: [
        { text: "全部", value: 0 },
        { text: "本科及以上", value: 1 },
        { text: "硕士及以上", value: 2 },
        { text: "博士", value: 3 },
      ],
      // educations: {
      //   0: "全部",
      //   1: "本科及以上",
      //   2: "硕士及以",
      //   3: "博士",
      // },
      currentType: 0,
      currentType1: "",
      currentAddress: 0,
      currentEducation: 0,
      list: [],
      positions: [],
      params: {
        page: 1,
        type_id: 1,
        limit: 20,
      },
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.getPosition();
    this.getInfo();
  },
  methods: {
    currentChange() {
      document.documentElement.scrollTop = 0;
      this.getInfo();
    },
    goResume() {
      this.$router.push("/recruit-resume");
    },
    onSelect(action) {
      this.$router.push(action.url);
    },
    clickNav(e) {
      if (e == 0) {
        delete this.params.position;
        this.activeId = "";
        this.list = [];
        this.loading = false;
        this.finished = false;
        this.params.page = 1;
        // this.getInfo();
        this.$refs.item.toggle();
      } else {
        this.params.position = `${this.positions[this.activeIndex].id},0`;
        this.list = [];
        this.loading = false;
        this.finished = false;
        this.params.page = 1;
      }
    },
    clickItem(e) {
      this.params.position = `${this.positions[this.activeIndex].id},${e.id}`;
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.params.page = 1;
      this.$refs.item.toggle();
    },
    recruitChange(e) {
      this.params.type_id = e;
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.params.page = 1;
      // this.getInfo();
    },
    educationChange(e) {
      if (e == 0) {
        delete this.params.education;
      } else {
        this.params.education = e - 1;
      }
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.params.page = 1;
    },
    addressChange(e) {
      if (e == 0) {
        delete this.params.city;
      } else {
        this.params.city = this.address[e].text;
      }
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.params.page = 1;
    },

    mouseenter(e) {
      var index = e.target.querySelector("span").className;
      if (this.currentType === parseInt(index)) return;
      this.currentType = parseInt(index);
      this.currentType1 = "";
      if (index == 0) {
        delete this.params.position;
      } else {
        this.params.position = `${this.positions[this.currentType].id},0`;
      }
      this.params.page = 1;
      this.list = [];
      this.getInfo();
    },
    changeType1(item, index) {
      this.params.position = `${this.positions[this.currentType].id},${
        item.id
      }`;
      this.currentType1 = index;
      this.params.page = 1;
      this.list = [];
      this.getInfo();
    },
    changeType(index) {
      if (index == 0) {
        delete this.params.position;
      } else {
        this.params.position = `${this.positions[index].id},0`;
      }
      this.currentType1 = "";
      this.currentType = index;
      this.params.page = 1;
      this.list = [];
      this.getInfo();
    },
    changeEducation(index) {
      if (index == 0) {
        delete this.params.education;
      } else {
        this.params.education = index - 1;
      }
      this.currentEducation = index;
      this.getInfo();
    },
    changeAddress(item, index) {
      if (index == 0) {
        delete this.params.city;
      } else {
        this.params.city = item.text;
      }
      this.currentAddress = index;
      this.params.page = 1;
      this.list = [];
      this.getInfo();
    },
    getInfo() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.loading = true;
      info(this.params).then((res) => {
        if (flag) {
          this.list = [...this.list, ...res.data];
        } else {
          this.list = res.data;
        }
        this.total = res.count;
        this.loading = false;
        // 数据全部加载完成
        // if (res.data.length == 0) {
        //   this.finished = true;
        //   return;
        // }
        if (flag) {
          if (res.current == Math.ceil(res.count / res.limit)) {
            this.finished = true;
          } else {
            this.params.page++;
          }
        }
        // loading.close();
      });
    },
    getPosition() {
      position().then((res) => {
        this.positions = res.data;
        this.positions.unshift({ name: "全部", id: 0, lists: [] });
        this.positions.map((item) => {
          item.text = item.name;
          item.children = item.lists;
          if (item.lists.length > 0) {
            item.lists.map((item1) => {
              item1.text = item1.name;
            });
          }
        });
      });
    },

    goDetail(id) {
      this.$router.push({
        path: "/recruit-detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cf322e !important;
}
/deep/ .number:hover {
  color: #cf322e !important;
}
.page {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title-3 {
  font-size: 14px;
  font-weight: bold;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/ .van-nav-bar {
  background-color: #cf322e;
}
.tag {
  padding: 0 6px;
  height: 20px;
  margin-left: 10px;
  color: #333;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #409eff;
}
.social {
  margin-top: 80px;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
  }

  .type-list {
    margin-top: 20px;
    padding: 20px 20px 0 20px;
    margin-left: 90px;
    background: #f7f7f7;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;

    .item {
      cursor: pointer;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .active {
      color: #cf322e;
    }
  }
  .show {
    display: block;
  }
  .job {
    > li {
      cursor: pointer;
      padding: 8px 25px;
      background: #fafafa;
      border-radius: 8px;
      margin-right: 20px;
      font-size: 16px;
    }

    .active {
      color: #fff;
      background: #cf322e;
    }
  }

  .education {
    > li {
      cursor: pointer;
      font-size: 16px;
      margin-right: 24px;
    }

    .active {
      color: #cf322e;
    }
  }

  .list {
    margin-left: 90px;
    margin-top: 20px;

    > li {
      cursor: pointer;
      padding: 14px;
      margin-bottom: 10px;
      border-radius: 2px;
      border: 1px solid #cccccc;

      .name {
        font-size: 16px;
        // font-weight: bold;
      }

      .subtitle {
        margin-top: 6px;
        font-size: 12px;
        color: #666666;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
